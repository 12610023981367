import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import SlashElement from '../../Misc/SlashElement/SlashElement';

import { addEClaim, addNewPrivate, addNewTeleplan, addNewWSBCR } from '../../../modules/claims/helpers/addNewInvoice';
import { setClaimFilters } from '../../../modules/claims/actions/claims.action.creators';
import { claimsDefaultFilters } from '../../../modules/config/defaultValuesConfig';
import { patientFullNameWithAge } from '../../../modules/utils/patientFullName';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { routes } from '../../../routes/routes';
import { t } from '../../../service/localization/i18n';
import { showPolicy } from '../../../modules/config/specialitiesConfig';

const SuccessActionButtons = ({
  wsbcReportWithSamePatient,
  wsbcReportWithOtherPatient,
  billSameTeleplanPatient,
  billSameTeleplanPatientPOS,
  billSameTeleplanPatientGroup,
  billSamePrivatePatient,
  billOtherTeleplanPatient,
  billOtherTeleplanPatientPOS,
  billOtherTeleplanGoupPatient,
  billOtherPrivatePatient,
  billSameEClaim,
  openPatientProfile,
  firstVisit,
  goToPrevPage,
  newPatient,
  patientsList
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { showNewTeleplanButtons } = useSelector((state) => state.core.window);
  const showEClaimButton = showPolicy();

  const handleFirstVisit = () => {
    if (firstVisit) return firstVisit();
    return addNewTeleplan({ history, replaceRoute: true, firstVisit: true });
  };

  const handleBillSameTeleplanPatient = () => {
    if (billSameTeleplanPatient) return billSameTeleplanPatient();
    return addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, pos: false });
  };

  const handleBillSameTeleplanPatientPOS = () => {
    if (billSameTeleplanPatientPOS) return billSameTeleplanPatientPOS();
    return addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, pos: true });
  };

  const handleBillOtherTeleplanPatient = () => {
    if (billOtherTeleplanPatient) return billOtherTeleplanPatient();
    return history.replace(routes.newClaimRecord.path);
  };

  const handleBillOtherTeleplanPatientPOS = () => {
    if (billOtherTeleplanPatientPOS) return billOtherTeleplanPatientPOS();
    return history.replace(routes.teleplanPOS.path);
  };

  const handleBillSamePrivatePatient = () => {
    if (billSamePrivatePatient) return billSamePrivatePatient();
    return addNewPrivate({ history, patient_details: patientsList[0], replaceRoute: true });
  };

  const handleBillEClaimForSamePatient = () => {
    if (billSameEClaim) return billSameEClaim();
    return addEClaim({ history, patientGuid: patientsList[0]?.PatientGuid, replaceRoute: true });
  };

  const handleBillOtherPrivatePatient = () => {
    if (billOtherPrivatePatient) return billOtherPrivatePatient();
    return addNewPrivate({ history, replaceRoute: true });
  };

  const handleWSBCReportWithSamePatient = () => {
    if (wsbcReportWithSamePatient) return wsbcReportWithSamePatient();
    return addNewWSBCR({ history, patient_details: patientsList[0], replaceRoute: true });
  };

  const handleWSBCReportWithOtherPatient = () => {
    if (wsbcReportWithOtherPatient) return wsbcReportWithOtherPatient();
    return addNewWSBCR({ history, replaceRoute: true });
  };

  const handleBillSameTeleplanPatientGroup = () => {
    if (billSameTeleplanPatientGroup) return billSameTeleplanPatientGroup();
    return addNewTeleplan({ history, patient_details: patientsList[0], replaceRoute: true, group: true });
  };

  const handleBillOtherTeleplanGoupPatient = () => {
    if (billOtherTeleplanGoupPatient) return billOtherTeleplanGoupPatient();
    return history.replace(routes.teleplanGroup.path);
  };

  const handleOpenPatientProfile = (PatientGuid) => {
    if (openPatientProfile) return openPatientProfile(PatientGuid);

    dispatch(setClaimFilters(claimsDefaultFilters));
    return history.replace(`${routes.patientDetails.path}/${PatientGuid}`);
  };

  const handleNewPatient = () => {
    if (newPatient) return newPatient();
    return history.replace(`${routes.createPatient.path}/new`);
  };

  const handleGoToPrevPage = () => {
    if (goToPrevPage) return goToPrevPage();
    return history.goBack();
  };

  return (
    <div style={{display:'flow-root'}}>
      <div className="buttonsSectionsWrap" style={{ maxWidth: '1150px', display:'flex' }}>
        {/* BILL SAME PATIENT */}
        <div className="buttonsSections" style={{ width: '350px' }}>
          <div className="text-xl mb-5 font-bold" style={{textAlign:'center'}}>
            {t('Bill_same_patients')}
            {/* {`${t('Bill')} ${patientsList[0]?.LastName}, ${patientsList[0]?.FirstName}`} */}
          </div>

          <div className="flex flex-column align-items-center buttonsGap">
            {user.canBillMSP && (
              <>
                <div className="flex">
                  <Button
                    id={elementIDs.billSameTeleplanPatient}
                    className="p-button-rounded p-button-text py-2 w-max"
                    label={t('Teleplan')}
                    onClick={handleBillSameTeleplanPatient}
                  />

                  {!showNewTeleplanButtons && (
                    <>
                      <SlashElement />

                      <Button
                        id={elementIDs.billSameTeleplanPatientPOS}
                        className="p-button-rounded p-button-text py-2 w-max"
                        label={t('Picker')}
                        onClick={handleBillSameTeleplanPatientPOS}
                      />

                      <SlashElement />

                      <Button
                        id={elementIDs.billSameTeleplanPatient}
                        className="p-button-rounded p-button-text py-2 w-max"
                        label={t('Batch')}
                        onClick={handleBillSameTeleplanPatientGroup}
                      />
                    </>
                  )}
                </div>
              </>
            )}

            <Button
              id={elementIDs.billSamePrivatePatient}
              className="p-button-rounded p-button-text py-2 w-max"
              label={t('Private_invoice')}
              onClick={handleBillSamePrivatePatient}
            />

            {user.canBillWCB && (
              <Button
                id={elementIDs.billSameWSBCRPatient}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('WSBC_Report')}
                onClick={handleWSBCReportWithSamePatient}
              />
            )}

            {showEClaimButton && (
              <Button
                id={elementIDs.billSameEClaim}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('eClaims')}
                onClick={handleBillEClaimForSamePatient}
              />
            )}
          </div>
        </div>

        {/* BILL OTHER PATIENT */}
        <div className="buttonsSections" style={{ width: '350px' }}>
          <div className="text-xl mb-5 font-bold" style={{textAlign:'center'}}>{t('Bill_other_patients')}</div>

          <div className="flex flex-column align-items-center buttonsGap">
            {user.canBillMSP && (
              <>
                <div className="flex">
                  <Button
                    id={elementIDs.billOtherTeleplanPatient}
                    className="p-button-rounded p-button-text py-2 w-max"
                    label={t('Teleplan')}
                    onClick={handleBillOtherTeleplanPatient}
                  />

                  {!showNewTeleplanButtons && (
                    <>
                      <SlashElement />

                      <Button
                        id={elementIDs.billSameTeleplanPatientPOS}
                        className="p-button-rounded p-button-text py-2 w-max"
                        label={t('Picker')}
                        onClick={handleBillOtherTeleplanPatientPOS}
                      />

                      <SlashElement />

                      <Button
                        id={elementIDs.billSameTeleplanPatient}
                        className="p-button-rounded p-button-text py-2 w-max"
                        label={t('Batch')}
                        onClick={handleBillOtherTeleplanGoupPatient}
                      />
                    </>
                  )}

                  <SlashElement />

                  <Button
                    id={elementIDs.billOtherTeleplanFirstVisitPatient}
                    className="p-button-rounded p-button-text py-2 w-max"
                    label={t('First_visit')}
                    onClick={handleFirstVisit}
                  />
                </div>
              </>
            )}

            <Button
              id={elementIDs.billOtherPrivatePatient}
              className="p-button-rounded p-button-text py-2 w-max"
              label={t('Private_invoice')}
              onClick={handleBillOtherPrivatePatient}
            />

            {user.canBillWCB && (
              <Button
                id={elementIDs.billOtherWSBCRPatient}
                className="p-button-rounded p-button-text py-2 w-max"
                label={t('WSBC_Report')}
                onClick={handleWSBCReportWithOtherPatient}
              />
            )}
          </div>
        </div>

        {/* OPEN PROFILE */}
        <div className="buttonsSections" style={{ width: '350px' }}>
          <div className="text-xl mb-5 font-bold" style={{textAlign:'center'}}>{t('Open_profile')}</div>

          <Button
            id={elementIDs.newPatient}
            className="p-button-rounded p-button-text pb-3 w-max"
            style={{ margin:'auto', display:'flex' }}
            icon="pi pi-plus"
            label={t('New_Patient')}
            onClick={handleNewPatient}
          />

          <div className="flex flex-column align-items-center buttonsGap">
            {patientsList?.map((i, index) => {
              return (
                <Button
                  key={i.PatientGuid}
                  id={`${elementIDs.openPatientProfile}_${index}`}
                  className="p-button-rounded p-button-text py-2 w-max"
                  label={patientFullNameWithAge(i)}
                  onClick={() => handleOpenPatientProfile(i.PatientGuid)}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex w-full justify-content-center" style={{ paddingTop: '70px' }}>
        <Button
          id={elementIDs.goToPreviousPage}
          className="p-button-rounded p-button-text"
          label={t('Go_to_previous_page')}
          onClick={handleGoToPrevPage}
        />
      </div>
    </div>
  );
};

export default SuccessActionButtons;
